<template>
	<div class="">
		<div class="admin-structure">
			<div class="admin-listview-hold">
				<div class="searchbox-hold">
					<input type="text" v-model="searchboxval" class="searchbox" placeholder="Search">
					<span class="btn primary" @click.stop="addNewClient">
						<i class="mdi mdi-plus"></i> Add New
					</span>
				</div>
				<div class="admin-listview">
					<div v-for="li in shownObjects" :key="li.id"
						class="admin-list-row" :class="{highlighted : activeItemId === li.id}"
						@click="setActiveItem(li)"
					>
						<span>{{li.business_name}}</span>
					</div>
				</div>
			</div>
			<div class="admin-infoview">
				<ClientCard :activeitem="activeItem"></ClientCard>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ClientCard from "@/components/ClientCard.vue";

export default {
	name: 'ClientsContent',
	components: {
		ClientCard

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			hasMounted: false,
			activeItemId: null,
			searchboxval: '',
		}
	},
	computed: {
		storeClients() {
			return this.store.clients
		},
		shownObjects() {
			let rows = this.storeClients
			if(this.searchboxval.length > 0) { //add any searchfilter by text input here
				let newer = [];
				let sboxval = this.searchboxval.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(r[fld] && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			return rows
		},
		activeItem() {
			if(this.activeItemId === null) return null
			return this.storeClients.find(a => a.id === this.activeItemId)
		},
		rowData() {
			if(this.hasMounted === false) return []
			let rows = []
			for(let a of this.storeClients) {
				//if(a.deleted === 0 && a.exclude === 0) {
					rows.push(a)
				//}
			}
			return rows
		},
	},
	methods: {
		setActiveItem(item) {
			this.activeItemId = item.id
		},
	},
	watch: {
		storeClients: {
			handler: function () {

			},
			deep: true
		},
		'store.setNewClientId': function() {
			this.activeItemId = this.store.setNewClientId
		},
	},
	props: {

	},
	mounted() {
		this.hasMounted = true
	},
	created() {
		document.title = "Clients - ACE Digital"
	},
}
</script>

<style scoped>

</style>
