<template>
	<div v-if="noteob" class="note">
		<div class="story-note">
			<span>{{noteob.createdNice}}: {{getUsernameFromId(noteob.user_id)}}</span>
			<div class="story-note-edit-buttons">
				<span class="del" v-if="noteob.user_id === store.user.id" @click.stop="deleteNote">
					<i class="mdi mdi-close"></i>
				</span>
				<span class="edit" v-if="noteob.user_id === store.user.id" @click.stop="editNote">
					<i class="mdi mdi-pencil"></i>
				</span>
			</div>
		</div>
		<div v-html="ntobr(noteob.noteText)"></div>

		<NoteEditPopup v-if="noteEditPopupShown" :itemobj="noteob" :parentitemid="parentitemid" :notetype="notetype" @close="noteEditPopupShown = false"></NoteEditPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import NoteEditPopup from "@/components/NoteEditPopup.vue";

export default {
	name: 'NoteItem',
	components: {
		NoteEditPopup

	},
	props: {
		noteob: {
			type: Object,
			default: null
		},
		notetype: null, //project, client, contact
		parentitemid: null,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			noteEditPopupShown: false,

		}
	},
	computed: {

	},
	methods: {
		editNote() {
			this.noteEditPopupShown = true
		},
		deleteNote() {
			let self = this
			axios.post(
				"post/delete-note.php",
				JSON.stringify({
					itemType: this.notetype,
					noteItemId: this.noteob.id,
					parentItemId: this.parentitemid
				})
			).then(function (response) {
				let ret = response.data //response.data is returned info
				if(ret.notes) {
					if(self.notetype === 'project') self.store.notesByProjectId[self.parentitemid] = ret.notes
					else if(self.notetype === 'client') self.store.notesByClientId[self.parentitemid] = ret.notes
					else if(self.notetype === 'contact') self.store.notesByContactId[self.parentitemid] = ret.notes
					self.$emit('close')
				}
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.admin-notes-section-text {
	line-height: 1.2;
}
.note {
	margin: 10px 0;
	padding: 10px 0;
	line-height: 1.2;
	border-top: 1px solid #EEE;
	font-size: 12px;
}
.story-note-edit-buttons {
	display: flex;
}
.story-note {
	font-size: 10px;
	color: #666;
	margin: 5px 0;
	display: flex;
	justify-content: space-between;
}
.story-note-edit-buttons span {
	cursor: pointer;
	margin-left: 5px;
	font-size: 14px;
}
.story-note-edit-buttons span.del {
	color: #EE0000;
}
.story-note-edit-buttons span.edit {
	color: var(--bluedark);
}
</style>
