<template>
	<div class="sites-list">

		<div class="inner">
			<ALTable :searchbox="true" :rowdata="sitesRowData" tblclasses="noborderv" :pagination="50"
				:searchablefields="['label']" :excel="true"
				:cols="[
					{title: 'Station', fldid:'label', sortable: true},
					{title: 'Sess P28D', fldid:'p28dSessions', sortable: true, align: 'right', numtype: 'number'},
					{title: 'Views P28D', fldid:'p28dViews', sortable: true, align: 'right', numtype: 'number'},
					{title: 'Uniques P28D', fldid:'p28dUniques', sortable: true, align: 'right', numtype: 'number'},
					{title: 'Pct Cache', fldid:'p28dCachedPct', sortable: true, align: 'right', numtype: 'pct'},
					{title: 'Pct Mobile', fldid:'p28dMobilePct', sortable: true, align: 'right', numtype: 'pct'},
				]"
			>

			</ALTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ALTable from "@/components/ALTable.vue";

export default {
	name: 'SupernetSitesContent',
	components: {
		ALTable,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			hasMounted: false,

		}
	},
	computed: {
		sitesRowData() {
			if(this.hasMounted === false) return []
			let rows = []
			for(let a of this.store.superNetworkAnalytics) {
					let myrow = {
						label: a.site,
						p28dSessions: a.analytics.p28d.sessions,
						p28dViews: a.analytics.p28d.hits,
						p28dCachedPct: a.analytics.p28d.cached_pct,
						p28dUniques: a.analytics.p28d.unique_ips,
						p28dMobilePct: a.analytics.p28d.mobile_pct,
					}
					rows.push(myrow)
			}
			return rows
		},
	},
	methods: {

	},
	watch: {},
	props: {

	},
	mounted() {
		this.hasMounted = true
	},
	created() {
		document.title = "Super Radio Network - ACE Digital"
	},
}
</script>

<style scoped>
.inner {
	padding-left: 50px;
	padding-right: 50px;
}
</style>
