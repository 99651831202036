<template>
	<div>
		<BlanketOverlayWithPopup v-if="itemobj" :title="headerLabel" :noinsidepadding="true"
			@close="$emit('close')" width="600px">
			<template v-slot:main>
				<div class="admin-info-section">
					<p class="item-label">Project Name</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="project_name">
					</div>
					<p class="item-label">Client</p>
					<div class="input-holder">
						<select v-model="client_id" class="select-styled">
							<option value=""></option>
							<option v-for="st in store.clients" :value="st.id" :key="st.id">{{st.business_name}}</option>
						</select>
					</div>
					<p class="item-label">Type</p>
					<div class="input-holder">
						<select v-model="project_type" class="select-styled">
							<option value=""></option>
							<option value="Website">Website</option>
						</select>
					</div>
					<p class="item-label">Site Type</p>
					<div class="input-holder">
						<select v-model="site_type" class="select-styled">
							<option value=""></option>
							<option v-for="st in store.siteTypes" :value="st" :key="st">{{st}}</option>
						</select>
					</div>
					<p class="item-label">Status</p>
					<div class="input-holder">
						<select v-model="status" class="select-styled">
							<option value=""></option>
							<option v-for="st in store.projectStatuses" :value="st" :key="st">{{st}}</option>
						</select>
					</div>
					<p class="item-label">Renewal Cadence</p>
					<div class="input-holder">
						<select v-model="renewal_cadence" class="select-styled">
							<option value="None">None</option>
							<option value="Monthly">Monthly</option>
							<option value="Yearly">Yearly</option>
						</select>
					</div>
					<p class="item-label">Renewal Date</p>
					<div class="input-holder">
						<!-- eslint-disable-next-line -->
						<date-picker v-model:value="renewal_next_date" valueType="format" format="YYYY-MM-DD"></date-picker>
						<br/><br/>
					</div>
					<p class="item-label">Renewal Amount</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="renewal_amount">
					</div>
					<p class="item-label">Date Deployed</p>
					<div class="input-holder">
						<!-- eslint-disable-next-line -->
						<date-picker v-model:value="date_deployed" valueType="format" format="YYYY-MM-DD"></date-picker>
						<br/><br/>
					</div>
					<p class="item-label">Domain</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="domain">
					</div>
				</div>
			</template>
			<template v-slot:buttons>
				<div class="button-holder">
					<button class="btn primary" @click="saveDetails">Save</button>
					<button class="btn" @click="$emit('close')">Cancel</button>
				</div>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import axios from "axios";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';

export default {
	name: 'ProjectEditPopup',
	components: {
		BlanketOverlayWithPopup,
		DatePicker,
	},
	props: {
		itemobj: Object,
	},
	mixins: [globalMixin],
	emits: ['close'],
	data() {
		return {
			store: store,

			project_name: '',
			project_type: '',
			domain: '',
			id: 0,
			client_id: 0,
			sale_type: '',
			status: '',
			site_type: '',
			renewal_cadence: '',
			renewal_next_date: '',
			renewal_amount: null,
			date_deployed: '',
		}
	},
	computed: {
		headerLabel() {
			if (this.itemobj && this.itemobj.id > 0) return this.itemobj.project_name
			return 'Add New Project'
		},
	},
	methods: {
		populate() {
			if(this.itemobj) {
				this.id = this.itemobj.id
				if(this.itemobj.project_name) this.project_name = this.itemobj.project_name
				if(this.itemobj.project_type) this.project_type = this.itemobj.project_type
				if(this.itemobj.domain) this.domain = this.itemobj.domain
				if(this.itemobj.client_id) this.client_id = this.itemobj.client_id
				if(this.itemobj.sale_type) this.sale_type = this.itemobj.sale_type
				if(this.itemobj.status) this.status = this.itemobj.status
				if(this.itemobj.site_type) this.site_type = this.itemobj.site_type
				if(this.itemobj.renewal_cadence) this.renewal_cadence = this.itemobj.renewal_cadence
				if(this.itemobj.renewal_next_date) this.renewal_next_date = this.itemobj.renewal_next_date
				if(this.itemobj.renewal_amount) this.renewal_amount = this.itemobj.renewal_amount
				if(this.itemobj.date_deployed) this.date_deployed = this.itemobj.date_deployed
			}
		},
		saveDetails() {
			let sendob = {
				id: this.id,
				project_name: this.project_name,
				project_type: this.project_type,
				domain: this.domain,
				client_id: this.client_id,
				sale_type: this.sale_type,
				status: this.status,
				site_type: this.site_type,
				renewal_cadence: this.renewal_cadence,
				renewal_next_date: this.renewal_next_date,
				renewal_amount: this.renewal_amount,
				date_deployed: this.date_deployed,
			}
			let targ = "post/projects/save-details.php"
			let self = this
			axios.post(targ, JSON.stringify(sendob)).then(function (response) {
				let ret = response.data //response.data is returned info
				if(ret.project) {
					if(ret.isNew === true) {
						self.getProjects()
					}
					else {
						let index = self.store.projects.findIndex(x => x.id === ret.project.id)
						if(index !== -1) self.store.projects[index] = ret.project
						if(self.store.projectPopupOb && self.store.projectPopupOb.id === ret.project.id) {
							self.store.projectPopupOb = ret.project
						}
					}
					if(self.store.activePage === 'projects') {
						self.store.setNewProjectId = ret.project.id
					}
					self.$emit('close')
				}
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {
		itemobj: {
			handler: function () {
				this.populate()
			},
		},
	},
	mounted() {
		this.populate()
	},
	created() {

	},
}
</script>

<style scoped>

</style>
