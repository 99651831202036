<template>
	<div class="bgpage" style="min-height: 100vh;">
		<NavBar></NavBar>
		<SitesContent></SitesContent>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import NavBar from "@/components/NavBar.vue";
import SitesContent from "@/components/SitesContent.vue";
export default {
	name: 'SitesPage',
	components: {
		NavBar,
		SitesContent,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.store.activePage = 'sites'
	},
	created() {

	},
}
</script>

<style scoped>

</style>
