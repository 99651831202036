<template>
	<div class="bgpage" style="min-height: 100vh;">
		<NavBar></NavBar>
		<SupernetSitesContent></SupernetSitesContent>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import NavBar from "@/components/NavBar.vue";
import SupernetSitesContent from "@/components/SupernetSitesContent.vue";
export default {
	name: 'SupernetSitesPage',
	components: {
		NavBar,
		SupernetSitesContent,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.store.activePage = 'supernet'
	},
	created() {

	},
}
</script>

<style scoped>

</style>
