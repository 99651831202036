<template>
	<div class="bgpage" style="min-height: 100vh;">
		<NavBar></NavBar>
		<ContactsContent></ContactsContent>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import NavBar from "@/components/NavBar.vue";
import ContactsContent from "@/components/ContactsContent.vue";
export default {
	name: 'ContactsPage',
	components: {
		ContactsContent,
		NavBar,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.store.activePage = 'contacts'
	},
	created() {

	},
}
</script>

<style scoped>

</style>
