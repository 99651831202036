<template>
	<div>
		<div v-if="editor" class="tt-menu">
			<button onclick="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">Bold</button>
		</div>
		<editor-content :editor="editor" />
	</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
	name: 'TipTap',
	components: {
		EditorContent,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			editor: null,
		}
	},
	watch: {
		modelValue(value) {
			// HTML
			const isSame = this.editor.getHTML() === value
			// JSON
			// const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
			if (isSame) return
			this.editor.commands.setContent(value, false)
		},
	},
	mounted() {
		this.editor = new Editor({
			extensions: [
				StarterKit,
			],
			content: this.modelValue,
			onUpdate: () => {
				// HTML
				this.$emit('update:modelValue', this.editor.getHTML())

				// JSON
				// this.$emit('update:modelValue', this.editor.getJSON())
			},
		})
	},
	beforeUnmount() {
		this.editor.destroy()
	},
}
</script>

<style scoped>

</style>
