<template>
	<div class="sites-list">
		<div class="inner">
			<ALTable :searchbox="true" :rowdata="rowData" tblclasses="noborderv"
				:pagination="20" :excel="true"
				:cols="[
					{title: 'Date', fldid:'date', sortable: true},
					{title: 'Client ID', fldid:'client_name', sortable: true},
					{title: 'Project ID', fldid:'project_name', sortable: true},
					{title: 'Revenue Type', fldid:'revenue_type', filter: true},
					{title: 'Status', fldid:'status', filter: true},
					{title: 'Ex GST Amount', fldid:'amount_exgst', align: 'right', numtype: 'dollars'},
				]"
			>
			</ALTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ALTable from "@/components/ALTable.vue";

export default {
	name: 'RevenueContent',
	components: {
		ALTable


	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			hasMounted: false,
			activeItemId: null,
			searchboxval: '',
		}
	},
	computed: {
		shownObjects() {
			let rows = this.store.revenueLines
			if(this.searchboxval.length > 0) { //add any searchfilter by text input here
				let newer = [];
				let sboxval = this.searchboxval.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(r[fld] && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			return rows
		},
		activeItem() {
			if(this.activeItemId === null) return null
			return this.store.revenueLines.find(a => a.id === this.activeItemId)
		},
		rowData() {
			if(this.hasMounted === false) return []
			let rows = []
			for(let a of this.store.revenueLines) {
				let clientName = this.store.clients.find(c => c.id === a.client_id).business_name
				let projectName = this.store.projects.find(p => p.id === a.project_id).project_name
				rows.push({
					date: a.date,
					client_name: clientName,
					project_name: projectName,
					revenue_type: this.ucfirst(a.revenue_type),
					status: this.ucfirst(a.status),
					amount_exgst: a.amount_exgst,
				})
			}
			return rows
		},
	},
	methods: {
		setActiveItem(item) {
			this.activeItemId = item.id
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.hasMounted = true
	},
	created() {
		document.title = "Revenue - ACE Digital"
	},
}
</script>

<style scoped>
.inner {
	padding: 0 50px;
}
</style>
