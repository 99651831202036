<template>
	<div>
		<BlanketOverlayWithPopup v-if="editNoteItem" title="Edit Note" :noinsidepadding="false"
			@close="$emit('close')" width="600px">
			<template v-slot:main>
				<textarea v-model="editNoteItem.noteText" id="notetextarea" class="note-textarea"></textarea>

				<tip-tap v-model="editNoteItem.noteText"></tip-tap>

			</template>
			<template v-slot:buttons>
				<button @click="saveNote" class="btn primary">Save</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import axios from "axios";
import TipTap from "@/components/TipTap.vue";

export default {
	name: 'NoteEditPopup',
	components: {
		TipTap,
		BlanketOverlayWithPopup,
	},
	props: {
		itemobj: Object,
		notetype: null,
		parentitemid: null,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			editNoteItem: null,
		}
	},
	computed: {

	},
	methods: {
		saveNote() {
			let self = this
			axios.post(
				"post/save-note.php",
				JSON.stringify({
					itemType: this.notetype,
					parentItemId: this.parentitemid,
					noteItem: this.editNoteItem
				})
			).then(function (response) {
				let ret = response.data //response.data is returned info
				if(ret.notes) {
					if(self.notetype === 'project') self.store.notesByProjectId[self.parentitemid] = ret.notes
					else if(self.notetype === 'client') self.store.notesByClientId[self.parentitemid] = ret.notes
					else if(self.notetype === 'contact') self.store.notesByContactId[self.parentitemid] = ret.notes
					else if(self.notetype === 'ticket') self.store.notesByTicketId[self.parentitemid] = ret.notes
					self.$emit('close')
				}
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {},
	mounted() {
		if(!this.itemobj) {
			this.editNoteItem = {
				id: 0,
				noteText: '',
				user_id: this.store.user.id
			}
		}
		else {
			this.editNoteItem = JSON.parse(JSON.stringify(this.itemobj))
		}
		// setTimeout(function() {
		// 	document.getElementById('notetextarea').focus()
		// }, 100)
	},
	created() {

	},
}
</script>

<style scoped>
.note-textarea {
	width: 100%;
	height: 200px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	font-size: 13px;
	font-family: 'Roboto', Arial, sans-serif;
	resize: none;
}
</style>
