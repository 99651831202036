import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueApexCharts from "vue3-apexcharts";
//import vue3GoogleLogin from 'vue3-google-login'
// import Exceljs from "exceljs";

import LoginPage from "@/components/pages/LoginPage";
import HomePage from "@/components/pages/HomePage";
import SitesPage from "@/components/pages/SitesPage.vue";
import ClientsPage from "@/components/pages/ClientsPage.vue";
import ContactsPage from "@/components/pages/ContactsPage.vue";
import ServersPage from "@/components/pages/ServersPage.vue";
import AsanaPage from "@/components/pages/AsanaPage.vue";
import ProjectsPage from "@/components/pages/ProjectsPage.vue";
import RevenuePage from "@/components/pages/RevenuePage.vue";
import TicketsPage from "@/components/pages/TicketsPage.vue";
import StationSitesPage from "@/components/pages/StationSitesPage.vue";
import SupernetSitesPage from "@/components/pages/SupernetSitesPage.vue";

import './style.css'

//routing
const routes = [
	{path: '/login', component: LoginPage},
	{path: '/sites', component: SitesPage},
	{path: '/contacts', component: ContactsPage},
	{path: '/servers', component: ServersPage},
	{path: '/asana', component: AsanaPage},
	{path: '/clients', component: ClientsPage},
	{path: '/projects', component: ProjectsPage},
	{path: '/revenue-lines', component: RevenuePage},
	{path: '/tickets', component: TicketsPage},
	{path: '/station-sites', component: StationSitesPage},
	{path: '/super-network', component: SupernetSitesPage},
	{path: '/', component: HomePage},
]

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

//initialise app
const app = createApp(App)
app.use(router);
app.use(VueApexCharts);
app.mount('#app')
