<template>
	<div>
		<BlanketOverlayWithPopup v-if="itemobj" :title="itemobj.label"
			@close="$emit('close')" :nobuttons="true" width="1000px">
			<template v-slot:main>
				<div class="site-popup-url">
					<p class="liner" style="display: block; width: 100%;">
						<a :href="itemobj.rootUrl" target="_blank">{{itemobj.rootUrl}}</a>
					</p>
				</div>
				<div class="site-popup-content">
					<div class="left-side">
						<div v-if="itemobj.cms.length > 0" class="cms-main">
							<span class="cms-icon" v-html="itemobj.cms_icon"></span>
							<span>{{itemobj.cms_version}}</span>
						</div>
						<p v-if="itemobj.cms === 'WP'" class="liner"><span class="boldtitle">Theme:</span>{{itemobj.theme_name}}</p>
						<p class="liner"><span class="boldtitle">Site ID:</span>{{itemobj.ai}}</p>
						<p class="liner"><span class="boldtitle">Server:</span>{{itemobj.server_name}}</p>
						<p class="liner"><span class="boldtitle">Server IP:</span>{{itemobj.serverObject.public_ip}}</p>
						<p class="liner"><span class="boldtitle">Live Site:</span>{{intToYes(itemobj.isLive)}}</p>
						<p class="liner"><span class="boldtitle">Last Updated:</span>{{itemobj.lastfetchtimenice}}</p>
						<p class="liner">
							<span class="boldtitle">Cache:</span>
							<span v-if="itemobj.acecache_installed">Installed</span>
							<span v-if="itemobj.acecache_active">, Active</span>
						</p>

						<div v-if="itemobj.analytics && itemobj.analytics.p28d">
							<p class="liner" style="margin-top: 10px;"><span class="bold">Analytics (past 30 days)</span></p>
							<p>Sessions: {{itemobj.analytics.p28d.sessions}}</p>
							<p>Uniques: {{itemobj.analytics.p28d.unique_ips}}</p>
							<p>Page Views: {{itemobj.analytics.p28d.hits}}</p>
							<p>Cached Views: {{itemobj.analytics.p28d.cached_pct}}%</p>
							<p>Mobile Views: {{itemobj.analytics.p28d.mobile_pct}}%</p>
						</div>
					</div>
					<div v-if="itemobj.cms === 'WP'" class="right-side">
						<p class="bold">Plugins Active:</p>
						<ul class="plugins-ul">
							<li v-for="(p, ind) in itemobj.pluginObjects" :key="ind">{{p.title}} {{p.version}}</li>
						</ul>
					</div>
				</div>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'SiteContentPopup',
	components: {
		BlanketOverlayWithPopup

	},
	props: {
		itemobj: Object,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.site-popup-url {
	padding: 10px 0 0 0;
}
.site-popup-content {
	display: flex;
	gap: 50px;
	justify-content: space-between;
	padding: 20px 0 50px 0;
}
.site-popup-content .left-side,
.site-popup-content .right-side {
	flex: 1;
	width: 50%;
}
.site-popup-content .cms-main {
	display: flex;
	align-items: center;
	font-size: 24px;
	margin-bottom: 15px;
}
.boldtitle {
	font-weight: bold;
	width: 130px;
	display: inline-block;
}
.bold {
	font-weight: bold;
}
.liner {
	line-height: 1.2;
	margin-bottom: 5px;
}
ul.plugins-ul {
	margin-top: 5px;
	padding-left: 24px;
	list-style-type: disc;
	line-height: 1.5;
}
</style>
