<template>
	<div v-if="activeitemid && activeitem">
		<div class="admin-info-section">
			<span class="admin-item-title">{{activeitem.name}}</span>
			<p v-if="activeitem.parentGid != null" class="view-parent" @click="$emit('setactiveitem', activeitem.parentGid)">--- View Parent Task</p>
		</div>
		<div class="admin-tabs-section">
			<span class="admin-tab" @click="activesubtab = 'detail'" :class="{active : activesubtab === 'detail'}">Detail</span>
<!--			<span class="admin-tab" @click="activesubtab = 'notes'" :class="{active : activesubtab === 'notes'}">Notes</span>-->
		</div>
		<div v-if="activesubtab === 'detail'">
			<div class="admin-info-section info-data">
				<p><span class="item-label">Assignee:</span> {{getAsanaUserNameById(activeitem.assigneeId)}}</p>
				<p><span class="item-label">Due Date:</span> {{activeitem.dueOn}}</p>
				<p><span class="item-label">Projects:</span>
					<span v-for="p in asanaProjectObs" :key="p.id" class="chip chip-project">{{p.name}}</span>
				</p>
				<p><span class="item-label">Tags:</span>
					<span v-for="p in asanaTagObs" :key="p.id" class="chip chip-project">{{p.name}}</span>
				</p>
			</div>
			<div class="admin-info-section admin-notes-section-text">
				<p class="admin-item-label">Notes</p>
				<div v-html="ntobr(activeitem.notes)"></div>
				<div v-if="taskSubtasks.length > 0" style="margin-top: 20px;">
					<p class="admin-item-label">Subtasks</p>
					<div v-for="li in taskSubtasks" :key="li.id"
						class="admin-list-row" :class="{taskcompleted : li.completed}"
						@click="$emit('setactiveitem', li.id)"
					>
						<span>{{li.name}}</span>
					</div>
				</div>
				<p class="admin-item-label" style="margin: 20px 0 10px;">History</p>
				<div>
					<p class="story-note">Created: {{activeitem.createdAt}}</p>
					<div v-for="story in taskStories" :key="story.gid" class="asana-story" :class="{typesystem : story.type === 'system'}">
						<div v-if="story.type === 'system'">
							<p class="story-note">{{story.createdAtNice}} - {{getAsanaUserNameById(story.createdBy)}}: <span v-html="ntobr(story.text)"></span> </p>
						</div>
						<div v-else>
							<p class="story-note">{{story.createdAtNice}} - {{getAsanaUserNameById(story.createdBy)}}</p>
							<div v-html="ntobr(story.text)"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="activesubtab === 'notes'" style="padding-top: 10px;">

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";

export default {
	name: 'AsanaTaskCard',
	components: {

	},
	props: {
		activeitemid: {
			type: Number,
			default: null
		},
		tempitem: {
			type: Object,
			default: null
		},
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			activesubtab: 'detail',

			activeitem: null,
			taskProjectIds: [],
			taskTagIds: [],
			taskStories: [],
			taskAttachments: [],
			taskSubtasks: [],
		}
	},
	computed: {
		asanaProjectObs() {
			let obs = []
			for(let p of this.taskProjectIds) {
				let ob = this.store.asanaProjects.find(x => x.id === p)
				if(ob) obs.push(ob)
			}
			return obs
		},
		asanaTagObs() {
			let obs = []
			for(let t of this.taskTagIds) {
				let ob = this.store.asanaTags.find(x => x.id === t)
				if(ob) obs.push(ob)
			}
			return obs
		},
	},
	methods: {
		getItemDeepData() {
			if(this.activeitemid && this.activeitemid > 0) {
				let self = this
				this.activeitem = null
				this.taskProjectIds = []
				this.taskTagIds = []
				this.taskStories = []
				this.taskAttachments = []
				axios.post(
					// "post/asana/get-task-deep.php",
					"post/asana-api/get-task-deep.php",
					JSON.stringify({taskId: this.activeitemid})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					self.activeitem = ret.task
					self.taskProjectIds = ret.projIds
					self.taskTagIds = ret.tagIds
					self.taskStories = ret.stories
					self.taskAttachments = ret.attachments
					self.taskSubtasks = ret.subtasks
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		getAsanaUserById(id) {
			let ob = this.store.asanaUsers.find(x => x.id === id)
			if(ob) {
				return ob
			}
			return null
		},
		getAsanaUserNameById(id) {
			let ob = this.getAsanaUserById(id)
			if(ob) {
				return ob.name
			}
			return ''
		},
	},
	watch: {
		activeitemid() {
			this.getItemDeepData()
		},
		tempitem() {
			this.activeitem = this.tempitem
		},
	},
	mounted() {
		this.getItemDeepData()
	},
	created() {

	},
}
</script>

<style scoped>
.chip {
	font-size: 11px;
	padding: 5px 8px;
	margin-right: 5px;
	border-radius: 5px;
	background-color: #EEE;
}
.admin-notes-section-text {
	line-height: 1.2;
}
.asana-story {
	margin: 10px 0;
	padding: 10px 0;
	border-top: 1px solid #EEE;
	font-size: 12px;
}
.story-note {
	font-size: 10px;
	color: #666;
	margin: 5px 0;
}
.taskcompleted {
	font-style: italic;
	color: #AAA;
}
.view-parent {
	margin-top: 5px;
	font-size: 12px;
	cursor: pointer;
	display: inline-block;
}
.view-parent:hover {
	text-decoration: underline;
}
.asana-story.typesystem {
	margin: 0;
	padding: 5px 0;
	border-top: none;
}
.asana-story.typesystem .story-note {
	margin: 0;
}
</style>
