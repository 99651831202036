import { reactive } from 'vue'

let isDevServer = false;
let urlroot = 'https://console.ace.digital/' //api call (main) url
if (window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('.test') > -1) {
	isDevServer = true
	urlroot = 'https://ace-console-2024.test/'
}

export const store = reactive({
	kalert: {
		shown: false,
		text: "",
		type: 'danger',
	},
	isDevServer: isDevServer,
	urlroot: urlroot,
	userLoggedIn: false,
	user: {
		id: null,
		email: null,
		fname: null,
		lname: null,
		status: null,
		role: null,
	},

	activePage: null,

	apps: [],
	servers: [],
	plugins: [],
	users: [],
	clients: [],
	contacts: [],
	markets: [],
	industries: [],
	projects: [],
	revenueLines: [],
	tickets: [],
	salesReps: [],
	siteTypes: [],
	clientContactLinks: [],
	projectContactLinks: [],
	siteProjectLinks: [],
	aceStationSitesAnalytics: [],
	superNetworkAnalytics: [],

	notesByClientId: {},
	notesByContactId: {},
	notesByProjectId: {},
	notesByTicketId: {},

	emailsByTicketId: {},

	asanaProjects: [],
	asanaTags: [],
	asanaUsers: [],

	projectPopupOb: null,
	projectEditPopupOb: null,
	clientPopupOb: null,
	clientEditPopupOb: null,
	contactPopupOb: null,
	contactEditPopupOb: null,
	ticketPopupOb: null,
	ticketEditPopupOb: null,
	emailEditPopupOb: null,
	popupObTypeActive: null,
	popupObTypeHistory: [],

	mouseClickInFilter: false,
	mouseClickAnywhere: false,

	asanaTaskViewingSubtask: false,

	//some handlers
	setNewContactId: null,
	setNewClientId: null,
	setNewProjectId: null,
	setNewTicketId: null,

	clientStatuses: ['Lead','Pending','Active','Inactive','Cancelled'],
	// siteTypes: ['Custom','Managed','Essential','Ace Order','Managed (Landing)'],
	projectStatuses: ['Design','Dev','Complete','Cancelled'],

	//exceljs export formats
	// xlfmt: {
	// 	header: {font: {bold: true, name: 'Tahoma', size: 10}},
	// 	boldborder: {
	// 		font: {bold: true, name: 'Tahoma', size: 10},
	// 		border: xlborder
	// 	},
	// 	textborder: {
	// 		font: {name: 'Tahoma', size: 10},
	// 		border: xlborder
	// 	},
	// 	numberborder: {
	// 		font: {name: 'Tahoma', size: 10},
	// 		// numFmt: '#',
	// 		border: xlborder,
	// 	},
	// 	borderthead: {
	// 		font: {bold: true, name: 'Tahoma', size: 10},
	// 		border: xlborder,
	// 		fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFEAEAEA'}}
	// 	},
	// },

})