<template>
	<div>
		<BlanketOverlayWithPopup v-if="itemobj" :title="itemobj.full_name" :noinsidepadding="true"
			@close="$emit('close')" width="600px">
			<template v-slot:main>
				<div class="admin-info-section">
					<p class="item-label">First Name</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="first_name">
					</div>
					<p class="item-label">Last Name</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="last_name">
					</div>
					<p class="item-label">Email</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="email">
					</div>
					<p class="item-label">Phone</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="phone">
					</div>
				</div>
			</template>
			<template v-slot:buttons>
				<div class="button-holder">
					<button class="btn primary" @click="saveDetails">Save</button>
					<button class="btn" @click="$emit('close')">Cancel</button>
				</div>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import axios from "axios";

export default {
	name: 'ContactEditPopup',
	components: {
		BlanketOverlayWithPopup,

	},
	props: {
		itemobj: Object,
	},
	mixins: [globalMixin],
	emits: ['close'],
	data() {
		return {
			store: store,

			first_name: '',
			last_name: '',
			email: '',
			phone: '',
			id: 0,
		}
	},
	computed: {

	},
	methods: {
		populate() {
			if(this.itemobj) {
				this.id = this.itemobj.id
				if(this.itemobj.first_name) this.first_name = this.itemobj.first_name
				if(this.itemobj.last_name) this.last_name = this.itemobj.last_name
				if(this.itemobj.email) this.email = this.itemobj.email
				if(this.itemobj.phone) this.phone = this.itemobj.phone
			}
		},
		saveDetails() {
			let sendob = {
				id: this.id,
				first_name: this.first_name,
				last_name: this.last_name,
				email: this.email,
				phone: this.phone,
			}
			let targ = "post/contacts/save-details.php"
			let self = this
			axios.post(targ, JSON.stringify(sendob)).then(function (response) {
				let ret = response.data //response.data is returned info
				if(ret.contact) {
					if(ret.isNew === true) {
						self.getContacts()
					}
					else {
						let index = self.store.contacts.findIndex(x => x.id === ret.contact.id)
						if(index !== -1) self.store.contacts[index] = ret.contact
						if(self.store.contactPopupOb && self.store.contactPopupOb.id === ret.contact.id) {
							self.store.contactPopupOb = ret.contact
						}
					}
					if(self.store.activePage === 'contacts') {
						self.store.setNewContactId = ret.contact.id
					}
					self.$emit('close')
				}
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {
		itemobj: {
			handler: function () {
				this.populate()
			},
		},
	},
	mounted() {
		this.populate()
	},
	created() {

	},
}
</script>

<style scoped>

</style>
