<template>
	<div class="notesholder">
		<NoteItem v-for="noteob in notesobs" :noteob="noteob" :key="noteob.id" :notetype="notetype" :parentitemid="parentitemid"></NoteItem>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import NoteItem from "@/components/NoteItem.vue";

export default {
	name: 'NotesList',
	components: {
		NoteItem

	},
	props: {
		notesobs: {
			type: Array,
			default: function() {
				return []
			}
		},
		notetype: null, //project, client, contact
		parentitemid: null,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.notesholder {
	padding: 10px 20px;
}
</style>
