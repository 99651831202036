<template>
	<div class="">
		<div class="admin-structure">
			<div class="admin-listview-hold">
				<div class="searchbox-hold">
					<div class="autocomplete-hold" style="width: 300px;">
						<Autocompleter :searchlist="store.asanaProjects" idfldname="id" textfldname="name"
							:incomingval="chosenAsanaProjectGID" v-on:update="chosenAsanaProjectGID = ($event).id" v-model="chosenAsanaProjectGID" />
					</div>
					<input type="text" v-model="searchboxval" class="searchbox" placeholder="Search">
					<div class="as-check-hold">
						<label class="as-check"><input type="checkbox" v-model="showComplete" />Show Complete</label>
						<label class="as-check"><input type="checkbox" v-model="showIncomplete" />Show Incomplete</label>
					</div>
				</div>
				<div class="admin-listview">
					<div v-for="li in shownObjects" :key="li.id"
						class="admin-list-row" :class="{highlighted : activeItemId === li.id, taskcompleted : li.completed}"
						@click="setActiveItem(li.id)"
					>
						<span>{{li.name}}</span>
					</div>
				</div>
			</div>
			<div class="admin-infoview">
				<AsanaTaskCard :tempitem="activeItem" :activeitemid="activeItemId" @setactiveitem="setActiveItem($event)"></AsanaTaskCard>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import Autocompleter from "@/components/Autocompleter.vue";
import AsanaTaskCard from "@/components/AsanaTaskCard.vue";

export default {
	name: 'AsanaContent',
	components: {
		AsanaTaskCard,
		Autocompleter

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			hasMounted: false,
			activeItemId: null,
			searchboxval: '',
			showComplete: false,
			showIncomplete: true,

			chosenAsanaProjectGID: null,
			tasks: [],
		}
	},
	computed: {
		shownObjects() {
			let rows = this.tasks
			if(this.searchboxval.length > 0) { //add any searchfilter by text input here
				let newer = []
				let sboxval = this.searchboxval.toLowerCase()
				for (let r of rows) {
					for (let fld in r) {
						if(r[fld] && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r)
								break
							}
						}
					}
				}
				rows = newer;
			}
			if(this.showComplete === false) rows = rows.filter(a => a.completed !== 1)
			if(this.showIncomplete === false) rows = rows.filter(a => a.completed !== 0)
			return rows
		},
		activeItem() {
			if(this.activeItemId === null) return null
			return this.tasks.find(a => a.id === this.activeItemId)
		},
		rowData() {
			if(this.hasMounted === false) return []
			let rows = []
			for(let a of this.store.tasks) {
				//if(a.deleted === 0 && a.exclude === 0) {
					rows.push(a)
				//}
			}
			return rows
		},
	},
	methods: {
		setActiveItem(itemid) {
			this.activeItemId = itemid
		},
		getAsanaTasksForProject(projectGID) {
			let self = this
			this.tasks = []
			axios.post(
				// "post/asana/get-tasks-for-project.php",
				"post/asana-api/get-tasks-for-project.php",
				JSON.stringify({projectGID: projectGID})
			).then(function (response) {
				let ret = response.data //response.data is returned info
				self.tasks = ret.tasks
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		chosenAsanaProjectGID() {
			this.getAsanaTasksForProject(this.chosenAsanaProjectGID)
		}
	},
	props: {

	},
	mounted() {
		this.hasMounted = true
	},
	created() {
		document.title = "Asana - ACE Digital"
	},
}
</script>

<style scoped>
.taskcompleted {
	font-style: italic;
	color: #AAA;
}
.as-check {
	display: block;
	font-size: 11px;
}
.story-note {
	font-size: 11px;
	color: #666;
	margin: 5px 0;
}
</style>
