<template>
	<div>
		<div class="tile-item" @click="showProjectPopup(item)">
			<div>{{item.project_name}}</div>
			<div v-if="canremove === true">
				<i @click.stop="$emit('remove', item.id)" class="mdi mdi-close"></i>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'ProjectTile',
	components: {

	},
	props: {
		item: {
			type: Object,
			default: () => {
				return {}
			}
		},
		canremove: {
			type: Boolean,
			default: false
		}
	},
	emits: ['remove'],
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
