<template>
	<div>
		<BlanketOverlayWithPopup v-if="itemobj" :title="itemobj.label" :noinsidepadding="true"
			@close="$emit('close')" :nobuttons="true" width="600px">
			<template v-slot:main>
				<ContactCard :activeitem="itemobj" :inpopup="true"></ContactCard>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import ContactCard from "@/components/ContactCard.vue";

export default {
	name: 'ContactContentPopup',
	components: {
		BlanketOverlayWithPopup,
		ContactCard,

	},
	props: {
		itemobj: Object,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.site-popup-url {
	padding: 10px 0 0 0;
}
.site-popup-content {
	display: flex;
	gap: 50px;
	justify-content: space-between;
	padding: 20px 0 50px 0;
}
.site-popup-content .left-side,
.site-popup-content .right-side {
	flex: 1;
	width: 50%;
}
.site-popup-content .cms-main {
	display: flex;
	align-items: center;
	font-size: 24px;
	margin-bottom: 15px;
}
.boldtitle {
	font-weight: bold;
	width: 130px;
	display: inline-block;
}
.bold {
	font-weight: bold;
}
.liner {
	line-height: 1.2;
	margin-bottom: 5px;
}
ul.plugins-ul {
	margin-top: 5px;
	padding-left: 24px;
	list-style-type: disc;
	line-height: 1.5;
}
</style>
