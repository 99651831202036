<template>
	<div class="bgpage" style="min-height: 100vh;">
		<NavBar></NavBar>
		<AsanaContent></AsanaContent>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import AsanaContent from "@/components/AsanaContent.vue";

export default {
	name: 'AsanaPage',
	components: {
		AsanaContent,
		NavBar,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {
		getAsanaInitial() {
			let self = this
			axios.post(
				// "post/asana/get-initial-asana.php",
				"post/asana-api/get-initial-asana.php",
				JSON.stringify({})
			).then(function (response) {
				let ret = response.data //response.data is returned info
				self.handleReturnedAsana(ret)
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.getAsanaInitial()
		this.store.activePage = 'asana'
	},
	created() {

	},
}
</script>

<style scoped>

</style>
