<template>
	<div>
		<BlanketOverlayWithPopup v-if="itemobj" :title="headingLabel" :noinsidepadding="true"
			@close="$emit('close')" width="600px">
			<template v-slot:main>
				<div class="admin-info-section">
					<p class="item-label">Business Name</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="business_name">
					</div>
					<p class="item-label">Industry</p>
					<div class="input-holder">
						<select v-model="industry" class="select-styled">
							<option value=""></option>
							<option v-for="ind in store.industries" :value="ind.name" :key="ind.id">{{ind.name}}</option>
						</select>
					</div>
					<p class="item-label">Address</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="address">
					</div>
					<p class="item-label">Town</p>
					<div class="input-holder">
						<input type="text" class="input-styled" v-model="town">
					</div>
					<p class="item-label">Market</p>
					<div class="input-holder">
						<select v-model="market" class="select-styled">
							<option value=""></option>
							<option v-for="mk in store.markets" :value="mk" :key="mk">{{mk}}</option>
						</select>
					</div>
					<p class="item-label">Ace Rep</p>
					<div class="input-holder">
						<select v-model="rep_email" class="select-styled">
							<option value=""></option>
							<option v-for="rep in store.salesReps" :value="rep.email" :key="rep.email">{{rep.full_name}} ({{rep.market}})</option>
						</select>
					</div>
					<p class="item-label">Status</p>
					<div class="input-holder">
						<select v-model="status" class="select-styled">
							<option value=""></option>
							<option v-for="st in store.clientStatuses" :value="st" :key="st">{{st}}</option>
						</select>
					</div>
					<p class="item-label">Initial Revenue</p>
					<div class="input-holder">
						<input type="number" class="input-styled" v-model="revenue_initial">
					</div>
				</div>
			</template>
			<template v-slot:buttons>
				<div class="button-holder">
					<button class="btn primary" @click="saveDetails">Save</button>
					<button class="btn" @click="$emit('close')">Cancel</button>
				</div>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import axios from "axios";

export default {
	name: 'ClientEditPopup',
	components: {
		BlanketOverlayWithPopup,

	},
	props: {
		itemobj: Object,
	},
	mixins: [globalMixin],
	emits: ['close'],
	data() {
		return {
			store: store,

			business_name: '',
			industry: '',
			address: '',
			town: '',
			market: '',
			rep_email: '',
			status: '',
			revenue_initial: 0,
			id: 0,
		}
	},
	computed: {
		headingLabel() {
			if (this.itemobj && this.itemobj.id > 0) return this.itemobj.business_name
			return 'Add New Client'
		},
	},
	methods: {
		populate() {
			if(this.itemobj) {
				this.id = this.itemobj.id
				if(this.itemobj.business_name) this.business_name = this.itemobj.business_name
				if(this.itemobj.industry) this.industry = this.itemobj.industry
				if(this.itemobj.address) this.address = this.itemobj.address
				if(this.itemobj.town) this.town = this.itemobj.town
				if(this.itemobj.market) this.market = this.itemobj.market
				if(this.itemobj.rep_email) this.rep_email = this.itemobj.rep_email
				if(this.itemobj.status) this.status = this.itemobj.status
				if(this.itemobj.revenue_initial) this.revenue_initial = this.itemobj.revenue_initial
			}
		},
		saveDetails() {
			let sendob = {
				id: this.id,
				business_name: this.business_name,
				industry: this.industry,
				address: this.address,
				town: this.town,
				market: this.market,
				rep_email: this.rep_email,
				status: this.status,
				revenue_initial: this.revenue_initial,
			}
			let targ = "post/clients/save-details.php"
			let self = this
			axios.post(targ, JSON.stringify(sendob)).then(function (response) {
				let ret = response.data //response.data is returned info
				if(ret.client) {
					if(ret.isNew === true) {
						self.getClients()
					}
					else {
						let index = self.store.clients.findIndex(x => x.id === ret.client.id)
						if(index !== -1) self.store.clients[index] = ret.client
						if(self.store.clientPopupOb && self.store.clientPopupOb.id === ret.client.id) {
							self.store.clientPopupOb = ret.client
						}
					}
					if(self.store.activePage === 'clients') {
						self.store.setNewClientId = ret.client.id
					}
					self.$emit('close')
				}
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {
		itemobj: {
			handler: function () {
				this.populate()
			},
		},
	},
	mounted() {
		this.populate()
	},
	created() {

	},
}
</script>

<style scoped>

</style>
