<template>
	<div class="chooser">
		<div class="autocomplete-hold" style="width: 300px;">
			<Autocompleter :searchlist="store.apps" idfldname="ai" textfldname="label"
				v-model="chosenId" v-on:update="chosenId = ($event).ai"
			/>
		</div>
		<i class="mdi mdi-content-save" @click.stop="$emit('newid', chosenId)"></i>
		<i class="mdi mdi-close" @click.stop="$emit('close')"></i>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import Autocompleter from "@/components/Autocompleter.vue";

export default {
	name: 'SiteChooser',
	components: {
		Autocompleter

	},
	props: {

	},
	mixins: [globalMixin],
	emits: ['newid','close'],
	data() {
		return {
			store: store,
			chosenId: null,
		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.chooser {
	display: flex;
	gap: 10px;
	align-items: center;
}
.mdi {
	font-size: 22px;
}
</style>
