<template>
	<div>
		<BlanketOverlayWithPopup v-if="itemobj" :title="itemobj.label" :noinsidepadding="true"
			@close="$emit('close')" :nobuttons="true" width="600px">
			<template v-slot:main>
				<ClientCard :activeitem="itemobj" :inpopup="true"></ClientCard>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import ClientCard from "@/components/ClientCard.vue";

export default {
	name: 'ClientContentPopup',
	components: {
		ClientCard,
		BlanketOverlayWithPopup
	},
	props: {
		itemobj: Object,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
