<template>
	<div v-if="activeitem" :class="{popupPad : inpopup === true}">
		<div class="admin-info-section flex-justify">
			<span class="admin-item-title">{{activeitem.name}}</span>
			<div>
				<i class="mdi mdi-pencil" @click.stop="showTicketEditPopup(activeitem)"></i>
			</div>
		</div>
		<div class="admin-tabs-section">
			<span class="admin-tab" @click="activesubtab = 'detail'" :class="{active : activesubtab === 'detail'}">Detail</span>
			<span class="admin-tab" @click="activesubtab = 'notes'" :class="{active : activesubtab === 'notes'}">Notes</span>
		</div>
		<div v-if="activesubtab === 'detail'">
			<div class="admin-info-section info-data">
				<ContactTile :item="fromContactOb"></ContactTile>
				<br/>
				<p><span class="item-label">Status:</span> {{activeitem.status}}</p>
				<p><span class="item-label">Type:</span> {{activeitem.type}}</p>
				<p><span class="item-label">Created:</span> {{activeitem.createDateTime}}</p>
				<p><span class="item-label">Assigned To:</span> {{activeitem.assigneeId}}</p>
			</div>
			<div class="admin-info-section">
				<p class="admin-item-label">Emails</p>
				<div>
					<div v-for="(te, ind) in ticketEmails" :key="ind"
						@click="displayEmail(te)" class="ticket-email-tile">
						{{te.dateTime}}: {{te.fromRaw}}<br/>{{te.subject}}
					</div>
				</div>
				<EmailViewPopup :itemobj="emailPopupOb" @close="hideEmailPopup"></EmailViewPopup>


<!--				<p class="admin-item-label">-->
<!--					Clients-->
<!--					<i class="mdi mdi-plus" @click="showClientChooser = true"></i>-->
<!--				</p>-->
<!--				<div v-if="clientObs && clientObs.length > 0">-->
<!--					<ClientTile v-for="cl in clientObs" :key="cl.id" :item="cl"-->
<!--						:canremove="true" @remove="removeClientFromContact(cl.id, activeitem.id)"-->
<!--					></ClientTile>-->
<!--				</div>-->
<!--				<ClientChooser v-if="showClientChooser" @newid="addClient($event)" @close="showClientChooser = false"></ClientChooser>-->

<!--				<p class="admin-item-label">Projects</p>-->
<!--				<div>-->
<!--					<ProjectTile v-for="pr in projectObs" :key="pr.id" :item="pr"-->
<!--						:canremove="directLinkProjectIds.includes(pr.id)" @remove="removeProjectFromContact(pr.id, activeitem.id)"-->
<!--					></ProjectTile>-->
<!--				</div>-->
<!--				<p class="admin-item-label">Sites</p>-->
<!--				<div>-->
<!--					<SiteTile v-for="si in siteObs" :key="si.ai" :item="si"></SiteTile>-->
<!--				</div>-->
			</div>
		</div>
		<div v-if="activesubtab === 'notes'" class="notes-section-hold">
			<div>
				<span class="note-button" @click="createNewNote">Add Note</span>
				<NoteEditPopup v-if="noteShowPopup" :itemobj="noteActiveOb" :parentitemid="activeitem.id" notetype="ticket" @close="closeNotePopup"></NoteEditPopup>
			</div>
			<NotesList :notesobs="ticketNotes"></NotesList>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
// import ClientTile from "@/components/ClientTile.vue";
// import SiteTile from "@/components/SiteTile.vue";
// import ProjectTile from "@/components/ProjectTile.vue";
import NotesList from "@/components/NotesList.vue";
import axios from "axios";
import NoteEditPopup from "@/components/NoteEditPopup.vue";
import ContactTile from "@/components/ContactTile.vue";
import EmailViewPopup from "@/components/EmailViewPopup.vue";
// import ClientChooser from "@/components/ClientChooser.vue";

export default {
	name: 'TicketCard',
	components: {
		EmailViewPopup,
		ContactTile,
		// ClientChooser,
		NoteEditPopup,
		NotesList,
		// SiteTile,
		// ClientTile,
		// ProjectTile
	},
	props: {
		activeitem: Object,
		inpopup: {
			type: Boolean,
			default: false
		},
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			activesubtab: 'detail',
			noteShowPopup: false,
			noteActiveOb: null,

			showClientChooser: false,
			emailPopupOb: null,
			showEmailViewPopup: false,
		}
	},
	computed: {
		ticketNotes() {
			return this.store.notesByTicketId[this.activeitem.id]
		},
		ticketEmails() {
			if(!this.store.emailsByTicketId[this.activeitem.id]) return []
			return this.store.emailsByTicketId[this.activeitem.id]
		},
		fromContactOb() {
			return this.store.contacts.find(it => it.id === this.activeitem.contactId)
		},
		// clientIds() {
		// 	return this.store.clientContactLinks.filter(it => it.contact_id === this.activeitem.contact_id).map(it => it.client_id)
		// },
		// clientObs() {
		// 	let items = []
		// 	if(this.clientIds.length > 0) {
		// 		items = this.store.clients.filter(cl => this.clientIds.includes(cl.id))
		// 	}
		// 	return items
		// },
		// directLinkProjectIds() {
		// 	return this.store.projectContactLinks.filter(it => it.contact_id === this.activeitem.id).map(it => it.project_id)
		// },
		// projectIds() {
		// 	let pids = this.store.projects.filter(it => this.clientIds.includes(it.client_id)).map(it => it.id)
		// 	for(let id of this.directLinkProjectIds) { //merge the two arrays
		// 		if(!pids.includes(id)) pids.push(id)
		// 	}
		// 	return pids
		// },
		// projectObs() {
		// 	let items = []
		// 	if(this.projectIds.length > 0) {
		// 		items = this.store.projects.filter(pr => this.projectIds.includes(pr.id))
		// 	}
		// 	return items
		// },
		// siteIds() {
		// 	return this.store.siteProjectLinks.filter(it => this.projectIds.includes(it.project_id) || this.clientIds.includes(it.client_id)).map(it => it.site_id)
		// },
		// siteObs() {
		// 	let items = []
		// 	if(this.siteIds.length > 0) {
		// 		items = this.store.apps.filter(si => this.siteIds.includes(si.ai))
		// 	}
		// 	return items
		// },
	},
	methods: {
		displayEmail(emailOb) {
			this.emailPopupOb = emailOb
			this.showEmailViewPopup = true
		},
		hideEmailPopup() {
			this.showEmailViewPopup = false
			this.emailPopupOb = null
		},
		createNewNote() {
			this.noteActiveOb = null
			this.noteShowPopup = true
		},
		closeNotePopup() {
			this.noteShowPopup = false
			this.noteActiveOb = null
		},
		getItemDeepData() {
			if(this.activeitem && this.activeitem.id && this.activeitem.id > 0) {
				let self = this
				this.fetchingInitialData = true
				axios.post(
					"post/tickets/get-ticket-deep.php",
					JSON.stringify({itemId: this.activeitem.id})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if(ret.notes) self.store.notesByTicketId[self.activeitem.id] = ret.notes
					if(ret.ticket) self.store.emailsByTicketId[self.activeitem.id] = ret.ticket.emails
				}).catch(function (error) {
					console.log(error)
				});
			}
		},
		// addClient(clientId) {
		// 	this.addClientToContact(clientId, this.activeitem.id)
		// 	this.showClientChooser = false
		// },
	},
	watch: {
		activeitem() {
			this.showClientChooser = false
			this.getItemDeepData()
		},
	},
	mounted() {
		this.showClientChooser = false
		this.getItemDeepData()
	},
	created() {

	},
}
</script>

<style scoped>

</style>
