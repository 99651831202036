<template>
	<div v-if="activeitem" :class="{popupPad : inpopup === true}">
		<div class="admin-info-section flex-justify">
			<span class="admin-item-title">{{activeitem.business_name}}</span>
			<div>
				<i class="mdi mdi-pencil" @click.stop="showClientEditPopup(activeitem)"></i>
			</div>
		</div>
		<div class="admin-tabs-section">
			<span class="admin-tab" @click="activesubtab = 'detail'" :class="{active : activesubtab === 'detail'}">Detail</span>
			<span class="admin-tab" @click="activesubtab = 'notes'" :class="{active : activesubtab === 'notes'}">Notes</span>
		</div>
		<div v-if="activesubtab === 'detail'">
			<div class="admin-info-section info-data">
				<p><span class="item-label">Name:</span> {{activeitem.business_name}}</p>
				<p><span class="item-label">Industry:</span> {{activeitem.industry}}</p>
				<p><span class="item-label">Address:</span> {{activeitem.address}}</p>
				<p><span class="item-label">Town:</span> {{activeitem.town}}</p>
				<p><span class="item-label">Ace Market:</span> {{activeitem.market}}</p>
				<p><span class="item-label">Ace Rep:</span> {{getRepNameFromEmail(activeitem.rep_email)}}</p>
				<p><span class="item-label">Status:</span> {{activeitem.status}}</p>
				<p><span class="item-label">Initial Revenue:</span> ${{activeitem.revenue_initial}}</p>
			</div>
			<div class="admin-info-section">
				<p class="admin-item-label">
					Contacts
					<i class="mdi mdi-plus" @click="showContactChooser = true"></i>
				</p>
				<div>
					<ContactTile v-for="ct in contactObs" :key="ct.id" :item="ct"
						:canremove="true" @remove="removeClientFromContact(activeitem.id, ct.id)"
					></ContactTile>
				</div>
				<ContactChooser v-if="showContactChooser" @newid="addContact($event)" @close="showContactChooser = false"></ContactChooser>

				<p class="admin-item-label">Projects</p>
				<div>
					<ProjectTile v-for="pr in projectObs" :key="pr.id" :item="pr"></ProjectTile>
				</div>
				<p class="admin-item-label">Sites</p>
				<div>
					<SiteTile v-for="si in siteObs" :key="si.ai" :item="si"></SiteTile>
				</div>
			</div>
		</div>
		<div v-if="activesubtab === 'notes'" class="notes-section-hold">
			<div>
				<span class="note-button" @click="createNewNote">Add Note</span>
				<NoteEditPopup v-if="noteShowPopup" :itemobj="noteActiveOb" :parentitemid="activeitem.id" notetype="client" @close="closeNotePopup"></NoteEditPopup>
			</div>
			<NotesList :notesobs="clientNotes"></NotesList>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ProjectTile from "@/components/ProjectTile.vue";
import SiteTile from "@/components/SiteTile.vue";
import ContactTile from "@/components/ContactTile.vue";
import NotesList from "@/components/NotesList.vue";
import NoteEditPopup from "@/components/NoteEditPopup.vue";
import ContactChooser from "@/components/ContactChooser.vue";

export default {
	name: 'ClientCard',
	components: {
		NoteEditPopup,
		NotesList,
		ContactTile,
		SiteTile,
		ProjectTile,
		ContactChooser,
	},
	props: {
		activeitem: Object,
		inpopup: {
			type: Boolean,
			default: false
		},
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			activesubtab: 'detail',
			noteShowPopup: false,
			noteActiveOb: null,
			showContactChooser: false,
		}
	},
	computed: {
		clientNotes() {
			return this.store.notesByClientId[this.activeitem.id]
		},
		contactIds() {
			return this.store.clientContactLinks.filter(it => it.client_id === this.activeitem.id).map(it => it.contact_id)
		},
		contactObs() {
			let items = []
			if(this.contactIds.length > 0) {
				items = this.store.contacts.filter(cl => this.contactIds.includes(cl.id))
			}
			return items
		},
		projectIds() {
			let pids = this.store.projects.filter(it => it.id === this.activeitem.id).map(it => it.id)
			return pids
		},
		projectObs() {
			let items = []
			if(this.projectIds.length > 0) {
				items = this.store.projects.filter(pr => this.projectIds.includes(pr.id))
			}
			return items
		},
		siteIds() {
			return this.store.siteProjectLinks.filter(it => this.projectIds.includes(it.project_id)).map(it => it.site_id)
		},
		siteObs() {
			let items = []
			if(this.siteIds.length > 0) {
				items = this.store.apps.filter(si => this.siteIds.includes(si.ai))
			}
			return items
		},
	},
	methods: {
		addContact(contactId) {
			this.addClientToContact(this.activeitem.id, contactId)
			this.showContactChooser = false
		},
		createNewNote() {
			this.noteActiveOb = null
			this.noteShowPopup = true
		},
		closeNotePopup() {
			this.noteShowPopup = false
			this.noteActiveOb = null
		},
		getItemDeepData() {
			if(this.activeitem && this.activeitem.id && this.activeitem.id > 0) {
				let self = this
				this.fetchingInitialData = true
				axios.post(
					"post/get-notes.php",
					JSON.stringify({itemType: 'client', itemId: this.activeitem.id})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if(ret.notes) {
						self.store.notesByClientId[self.activeitem.id] = ret.notes
					}
				}).catch(function (error) {
					console.log(error)
				});
			}
		},
	},
	watch: {
		activeitem() {
			this.getItemDeepData()
		},
	},
	mounted() {
		this.getItemDeepData()
	},
	created() {

	},
}
</script>

<style scoped>

</style>
