<template>
	<div>
		<BlanketOverlayWithPopup v-if="itemobj" :title="itemobj.subject" :noinsidepadding="true"
			@close="$emit('close')" :nobuttons="true" width="1000px">
			<template v-slot:main>
				<div class="email-view-wrap">
					<div class="functions-hold">
						<div class="action-button" @click="emailReplySetup">Reply</div>
					</div>
					<div class="atts-hold">
						<div v-for="att in itemobj.attachments" :key="att.id" class="att-tile">
							<a :href="att.fileurl" target="_blank" class="att-tile-a">
								<div class="filetype-sec" v-html="getAttPreview(att)">

								</div>
								<div class="title-section">
									{{att.filenameOriginal}}
								</div>
							</a>
						</div>
					</div>
					<div v-html="messageBody" class="email-hold"></div>
				</div>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'EmailViewPopup',
	components: {
		BlanketOverlayWithPopup,

	},
	props: {
		itemobj: Object,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {
		messageBody() {
			let body = this.itemobj.messageBody
			//replace any embedded/inline images
			let inlineAtts = this.itemobj.attachments.filter(att => att.isInline === 1)
			if (this.itemobj.bodyType === 'html') {
				for(let att of inlineAtts) {
					body = body.replace(`cid:${att.inlineId}`, att.fileurl)
				}
			}
			else if (this.itemobj.bodyType === 'plaintext') {
				body = this.ntobr(body)
				for(let att of inlineAtts) {
					body = body.replace(`[image: ${att.filenameOriginal}]`, `<img src='${att.fileurl}' />`)
					body = body.replace(`[cid:${att.inlineId}]`, `<img src='${att.fileurl}' />`)
				}
			}
			return body
		}
	},
	methods: {
		emailReplySetup() {
			let subject = this.itemobj.subject
			if(!subject.toLowerCase().startsWith('re: ')) subject = `RE: ${subject}`
			let ticketstr = `[#${this.itemobj.ticketId}]`
			if(!subject.includes(ticketstr)) subject += ` ${ticketstr}`
			let newEmailOb = {
				id: 0,
				ticketId: this.itemobj.ticketId,
				subject: subject,
				contactId: this.itemobj.contactId,
				attachments: [],
				messageBody: '\n\n--- Do not delete this line [#'+this.itemobj.ticketId+'] ----\n\n',
			}
			this.showEmailEditPopup(newEmailOb)
		},
		getAttPreview(att) {
			if(att.type === 5) { //image
				return `<img src="${att.fileurl}" style="height: 100%; width: 100%; object-fit:cover;" />`
			}
			else {
				let ft = ''
				if(att.type === 2) ft = 'MSG'
				else if(att.type === 3) ft = 'APP'
				else if(att.type === 4) ft = 'AUD'
				else if(att.type === 6) ft = 'VID'
				else if(att.type >= 7) ft = 'FILE'
				let ext = att.filenameOriginal.split('.').pop()
				if(['xlsx','xsl','xls','csv'].includes(ext)) ft = 'XLS'
				else if(['doc','docx'].includes(ext)) ft = 'DOC'
				else if(['ppt','pptx'].includes(ext)) ft = 'PPT'
				else if(['pdf'].includes(ext)) ft = 'PDF'
				else if(['zip'].includes(ext)) ft = 'ZIP'
				else if(['txt'].includes(ext)) ft = 'TXT'
				return `<span>${ft}</span>`
			}
		}
	},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>
<style scoped>
.email-view-wrap {
	padding: 40px;
	background-color: #EEE;
	position: relative;
}
.email-hold {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
	width: 100%;
	overflow: auto;
}
.functions-hold {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}
.atts-hold {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 10px;
}
.att-tile {
	background-color: white;
	border: 1px solid #CCC;
}
.att-tile-a {
	display: flex;
	align-items: center;
}
.filetype-sec {
	width: 50px;
	height: 50px;
	background-color: #EEE;
	text-align: center;
	position: relative;
	align-items: center;
	display: flex;
	justify-content: center;
}
.title-section {
	flex-grow: 1;
	padding: 5px 10px;
	font-size: 11px;
}
</style>
