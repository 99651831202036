<template>
	<div>

		<SiteContentPopup v-if="item && showPopupDetails === true" :itemobj="item"
			@close="showPopupDetails = false"
		></SiteContentPopup>

		<div class="tile-item" @click="showPopupDetails = true">
			<div>{{item.label}}</div>
			<div v-if="canremove === true">
				<i @click.stop="$emit('remove', item.id)" class="mdi mdi-close"></i>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import SiteContentPopup from "@/components/SiteContentPopup.vue";

export default {
	name: 'SiteTile',
	components: {
		SiteContentPopup,
	},
	props: {
		item: {
			type: Object,
			default: () => {
				return {}
			}
		},
		canremove: {
			type: Boolean,
			default: false
		}
	},
	emits: ['remove'],
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			showPopupDetails: false,

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
