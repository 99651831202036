<template>
	<div>
		<BlanketOverlayWithPopup v-if="this.store.emailEditPopupOb" title="Email" :noinsidepadding="false"
			@close="$emit('close')" width="600px">
			<template v-slot:main>
				<input type="text" v-model="subject" class="input" placeholder="Subject">
				<textarea v-model="messageBody" id="notetextarea" class="note-textarea"></textarea>
			</template>
			<template v-slot:buttons>
				<button @click="saveNote" class="btn primary">Save</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
// import axios from "axios";

export default {
	name: 'EmailEditPopup',
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		itemobj: Object,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			messageBody: '',
			subject: '',
		}
	},
	computed: {

	},
	methods: {
		// saveNote() {
		// 	let self = this
		// 	axios.post(
		// 		"post/save-note.php",
		// 		JSON.stringify({
		// 			itemType: this.notetype,
		// 			parentItemId: this.parentitemid,
		// 			noteItem: this.editNoteItem
		// 		})
		// 	).then(function (response) {
		// 		let ret = response.data //response.data is returned info
		// 		if(ret.notes) {
		// 			if(self.notetype === 'project') self.store.notesByProjectId[self.parentitemid] = ret.notes
		// 			else if(self.notetype === 'client') self.store.notesByClientId[self.parentitemid] = ret.notes
		// 			else if(self.notetype === 'contact') self.store.notesByContactId[self.parentitemid] = ret.notes
		// 			else if(self.notetype === 'ticket') self.store.notesByTicketId[self.parentitemid] = ret.notes
		// 			self.$emit('close')
		// 		}
		// 	}).catch(function (error) {
		// 		console.log(error)
		// 	});
		// },
	},
	watch: {},
	mounted() {
		if(this.itemobj) {
			this.messageBody = this.itemobj.messageBody
			this.subject = this.itemobj.subject
		}
		setTimeout(function() {
			document.getElementById('notetextarea').focus()
		}, 100)
	},
	created() {

	},
}
</script>

<style scoped>
.note-textarea {
	width: 100%;
	height: 200px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	font-size: 13px;
	font-family: 'Roboto', Arial, sans-serif;
	resize: none;
}
</style>
